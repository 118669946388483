var abp = abp || {};
(function () {

    /* DEFAULTS *************************************************/

    var defaultOptions = {
      position: 'top-end',
        showConfirmButton: false,
        timer: 4000,
        padding: 0,
       // toast: true,
      animation: false
    };

    /* NOTIFICATION *********************************************/

    var showNotification = function (type, message, title, options) {
      var icon = options.imageClass ? '<i class="iqsweeticon ' + options.imageClass + '"></i>' : '';

        if (title) {
          options.title = icon + '<span class="iqsweet">' + title + '</span>';
        }

      options.html = '<div class="iqsweetnoti">'+(title ? '' : icon) + '<span class="iqsweet">' + message + '</span></div>';
        var combinedOptions = Object.assign(defaultOptions, options);

        Swal.fire(combinedOptions);
    };

    abp.notify.success = function (message, title, options) {
        showNotification('success', message, title,
            Object.assign({
              background: '#ffffff',
              imageClass: 'fa fa-check-circle'
            }, options));
    };

    abp.notify.info = function (message, title, options) {
        showNotification('info', message, title, Object.assign({
          background: '#ffffff',
            imageClass: 'fa fa-info-circle'
        }, options));
    };

    abp.notify.warn = function (message, title, options) {
        showNotification('warning', message, title, Object.assign({
          background: '#ffffff',
            imageClass: 'fa fa-exclamation-triangle'
        }, options));
    };

    abp.notify.error = function (message, title, options) {
        showNotification('error', message, title, Object.assign({
            background: '#f4516c',
            imageClass: 'fa fa-exclamation-circle'
        }, options));
    };

})();
